import React from "react"
import "./footer.css"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "../fontello/css/fontello.css"
import { OutboundLink } from "gatsby-plugin-google-gtag"
const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(quality: 100, height:55) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    design_rush: file(relativePath: { eq: "design_rush.png" }) {
      childImageSharp {
        fixed(quality: 100, height:140) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    acrr_agency: file(relativePath: { eq: "AccreditedAgencyBlue.png" }) {
      childImageSharp {
        fixed(quality: 100, height:100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
const Footer = () => {
  const data = useStaticQuery(getLogo);
  const customeEvent = (data) => {
  
    if (typeof window !== "undefined") {
    window.gtag('event', 'click', data);
    
    }
  }
  return (
    <div>
      <div className="footer-dark">
        <footer>
          <div className="container">
          
            <div className="row">
              <div className="col-md-3 item">
              <Img className="logo_footer" fixed={data.logo.childImageSharp.fixed}/>
              <h4 className="logo_footer_text">TECHVIZON</h4>
             
              
                
                    </div>
                    
              <div className="col-md-3 item">
                <h4>Links</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about" >About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/career">Career</Link>
                  </li>
                  <li>
                    <Link onClick={()=>customeEvent({
        'event_category': 'Footer',
        'event_label': 'Contact_Us'
      })} to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 item">
                <h4>Services</h4>
                <ul>
                  <li>
                    <Link to="/services">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/services/#mobile-service-scroll">Mobile Solutions</Link>
                  </li>
                  <li>
                    <AnchorLink to="/services/#cloud-service-scroll">Cloud Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#devops-service-scroll">DevOps Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#ai-service-scroll">AI Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#blockchain-service-scroll">Blockchain Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#iot-service-scroll">IoT Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#bigdata-service-scroll">Bigdata Solutions</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/services/#startup-service-scroll">Startup Solutions</AnchorLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 item text">
                <h4>Contact Us</h4>
                <div className="contact-info">
                        <div className="footer-address-inf">
                            <h5 className="mb-2">Phone</h5>
                            <p><a onClick={()=>customeEvent({
        'event_category': 'Footer',
        'event_label': 'Call'
      })} href="tel:+916306396163">+91 6306396163</a></p>
                        </div>
                        <div className="footer-address-inf my-4">
                            <h5 className="mb-2">Email </h5>
                            <p><a onClick={()=>customeEvent({
        'event_category': 'Footer',
        'event_label': 'Mail'
      })} href="mailto:hi@techvizon.com">hi@techvizon.com</a></p>
                        </div>
                        <div className="footer-address-inf">
                            <h5 className="mb-2">Location</h5>
                            <p><a rel="noopener noreferrer nofollow" target="_blank" href="https://www.google.com/maps/place/Amrapali+Zodiac,+Sector+120,+Noida,+Uttar+Pradesh/@28.5843353,77.3958522,18z">Amrapali Zodiac, Sector 120, Noida, UP</a></p>
                        </div>
                    </div>
              </div>
            </div>
            <div className="row img-responsive text-center">
      <div className="col-xs-6 col-md-2 col-sm-6 col-lg-6 mx-auto my-auto">
      <OutboundLink aria-label="design_rush" rel="noopener noreferrer" target="_blank" href="https://www.designrush.com/agency/profile/techvizon">
                
                
      <Img fixed={data.design_rush.childImageSharp.fixed}/>
      </OutboundLink>
      
      </div>
      <div className="col-xs-6 col-md-2 col-sm-6 col-lg-6 mx-auto my-auto">
      <Img fixed={data.acrr_agency.childImageSharp.fixed}/>
      </div>
            </div>
            
            <div className="row">
        <div className="col-xs-12 item social mx-auto">
        <div className="py-2">Connect With Us</div>
         <OutboundLink aria-label="linkedin"  className="i-linkedin" rel="noopener noreferrer nofollow" target="_blank" href="https://www.linkedin.com/company/techvizon">
                <i className="icon-linkedin"></i>
                </OutboundLink>
                <OutboundLink aria-label="instagram" className="i-instagram" rel="noopener noreferrer nofollow" target="_blank" href="https://www.instagram.com/techvizonhq/">
                <i className="icon-instagram"></i>
                </OutboundLink>
                <OutboundLink aria-label="facebook" className="i-facebook" rel="noopener noreferrer nofollow" target="_blank" href="https://www.facebook.com/techvizonHQ">
                
                <i className="icon-facebook"></i>
                </OutboundLink>
                <OutboundLink aria-label="github" className="i-github" rel="noopener noreferrer nofollow" target="_blank" href="https://github.com/Techvizon">
                <i className="icon-github-circled"></i>
                </OutboundLink>
                <OutboundLink aria-label="quora" className="i-quora" rel="noopener noreferrer nofollow" target="_blank" href="https://www.quora.com/profile/Techvizon-HQ">
                <i className="icon-quora"></i>
                </OutboundLink>
               
                <OutboundLink aria-label="twitter" className="i-twitter" rel="noopener noreferrer nofollow" target="_blank" href="https://twitter.com/Techvizon_hq" target="_blank">
                <i className="icon-twitter"></i>
                </OutboundLink>
                <OutboundLink aria-label="reddit" className="i-reddit" rel="noopener noreferrer nofollow" target="_blank" href="https://www.reddit.com/user/techvizon">
                <i className="icon-reddit-alien"></i>
                </OutboundLink>
                
                
        </div>
        
    </div>
                
        
        



        <div className="row mx-auto py-3">
        <div className="mx-auto">
                    <a href="/sitemap">Sitemap</a>&nbsp; | &nbsp;<Link to="/privacy-policy">Privacy Policy</Link>&nbsp; | &nbsp;<Link to="/terms">Terms & Conditions</Link>
                  </div>
                            
        </div>        
                
          <div className="row">
        <div className="mx-auto">
          <a aria-label="dmca" rel="noopener noreferrer nofollow preconnect" target="_blank" href="//www.dmca.com/Protection/Status.aspx?ID=29b41643-a884-4d4c-84cb-8c32701ae6cb" title="DMCA.com Protection Status"> <img width="150" height="30" src ="https://images.dmca.com/Badges/dmca-badge-w150-5x1-01.png?ID=29b41643-a884-4d4c-84cb-8c32701ae6cb" loading="lazy"  alt="DMCA.com Protection Status" /></a></div>        
            
          </div>  
          <div className="row mx-auto">
          <div className="mx-auto">
            <p className="copyright">Techvizon Private Limited © {new Date().getFullYear()} - {new Date().getFullYear()+1} <br/>
            <small style={{color:'#2faff1'}}>Transforming Vision Into Reality</small></p>
            </div>
        </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Footer
