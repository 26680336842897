import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./header.css"
import $ from "jquery"

const getLogo = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fixed: file(relativePath: { eq: "ic_web.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed2: file(relativePath: { eq: "i_mobile.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100, height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed3: file(relativePath: { eq: "ic_cloud.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed4: file(relativePath: { eq: "ic_devops.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed5: file(relativePath: { eq: "ic_ai.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed6: file(relativePath: { eq: "ic_blockchain.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed7: file(relativePath: { eq: "ic_iot.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed8: file(relativePath: { eq: "ic_bigdata.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fixed9: file(relativePath: { eq: "ic_startup.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(getLogo)
  
  if (typeof window !== "undefined") {
    // browser code
    
    $(window).ready(function () {
      var navbar = $("#navbar-top")

      $(window).scroll(function () {
        if ($(window).scrollTop() <= 40) {
          navbar.removeClass("navbar-scroll")
        } else {
          navbar.addClass("navbar-scroll")
        }
      })
      $("[data-trigger]").on("click", function () {
        var trigger_id = $(this).attr("data-trigger")
        $(trigger_id).toggleClass("show")
        $("body").toggleClass("offcanvas-active")
      })

      // close button
      $("#btn-off-close").click(function (e) {
        $(".navbar-collapse").removeClass("show")
        $("body").removeClass("offcanvas-active")
      })
     
      $('[data-trigger="oclose"]').on("click",function(){
        if ($(window).width() <= 1000) {
          
        $(".navbar-collapse").removeClass("show")
        $("body").removeClass("offcanvas-active")
        }
      })
      

      if ($(window).width() <= 1000) {
        $("#serviceLink").attr({
          "data-toggle": "dropdown",
          "aria-expanded": "true",
        })
      } else {
        $("#serviceLink").removeAttr("data-toggle aria-expanded")
      }
    })
  }
  const customeEvent = data => {
    if (typeof window !== "undefined") {
      window.gtag("event", "click", data)
    }
  }
  return (
    <header>
      <nav
        id="navbar-top"
        className="scroll navbar navbar-light fixed-top navbar-expand-lg"
      >
        <Link aria-label="brand" className="navbar-brand" to="/">
          <Img fixed={data.logo.childImageSharp.fixed} className="" />
        </Link>
        <Link className="navbar-brand" to="/" className="navbar-brand brandName">
          <span>TECHVIZON</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-trigger="#main_nav"
          // data-toggle="collapse"
          // data-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse" id="main_nav">
          <div id="ofc" className="offcanvas-header mt-3">
            <a aria-label="brandoff" className="navbar-brand" href="/">
              <Img fixed={data.logo.childImageSharp.fixed} />
            </a>
            <a  href="/" className="navbar-brand brandName">
              <span>TECHVIZON</span>
            </a>
            <button
              id="btn-off-close"
              className="btn btn-outline-danger btn-close float-right"
            >
              Close{" "}
            </button>
          </div>
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              {" "}
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About Us
              </Link>
            </li>

            <li className="nav-item dropdown has-megamenu">
              <Link
                id="serviceLink"
                className="nav-link dropdown-toggle"
                to="/services"
              >
                {" "}
                Services{" "}
              </Link>

              <div className="dropdown-menu megamenu fade-down" role="menu">
                <div className="row py-3">
                  <div className="col-md-3">
                    <div className="col-megamenu">
                      <ul className="list-unstyled">
                        <li className="navli">
                          <Link data-trigger="oclose" to="/services">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed.childImageSharp.fixed}
                                  className="icon"
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Web Development</h5>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink   to="/services/#ai-service-scroll">
                            <div className="row">
                              <div className="col-md-2 mr-auto">
                                <Img
                                  fixed={data.fixed5.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>AI Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#startup-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed9.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Startup Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="col-megamenu">
                      <ul className="list-unstyled">
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#mobile-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed2.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Mobile Development</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#blockchain-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed6.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Blockchain Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="col-megamenu">
                      <ul className="list-unstyled">
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#cloud-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed3.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Cloud Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#iot-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed7.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>IoT Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="col-megamenu">
                      <ul className="list-unstyled">
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#devops-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed4.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>Devops Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                        <li data-trigger="oclose" className="navli">
                          <AnchorLink to="/services/#bigdata-service-scroll">
                            <div className="row">
                              <div className="col-md-2">
                                <Img
                                  fixed={data.fixed8.childImageSharp.fixed}
                                />
                              </div>
                              <div className="col my-auto">
                                <h5>BigData Solutions</h5>
                              </div>
                            </div>
                          </AnchorLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/about">
                Technologies
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/career">
                Career
              </Link>
            </li>
            <li className="nav-item">
              <Link
                
                className="nav-link"
                to="/contact"
                onClick={() =>
                  
                  customeEvent({
                    event_category: "calltoa",
                    event_label: "Header Contact",
                  })
                }
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                onClick={() =>
                  customeEvent({
                    event_category: "calltoa",
                    event_label: "Header Call To Action",
                  })
                }
                className="btn-grad"
                to="/contact"
              >
                Let's Discuss
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* {siteTitle} */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
